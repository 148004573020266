<template>
  <div class="wrapper_dialog_admin">
    <div class="relative">
      <transition name="fade" mode="out-in" appear>
        <div class="container">
          <div class="top">
            <span>管理后台面板</span>

          </div>
          
          <div class="wallet_items" >
            

      
              <router-link :to="{name:'y0JhsHKqX084c33heeDqKHmXfxOfgl2Q',path:'/y0JhsHKqX084c33heeDqKHmXfxOfgl2Q'}">一池管理后台</router-link>
              <router-link :to="{name:'AdminForPoolTwo',path:'/AdminForPoolTwo'}">二池管理后台</router-link>
              <router-link :to="{name:'AdminForBnbTreasurePool',path:'/AdminForBnbTreasurePool'}">币安宝管理后台</router-link>
              <router-link :to="{name:'eCITw3QJvxNz',path:'/eCITw3QJvxNz'}">彩票管理后台</router-link>
              

            
            
          </div>
          
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.div-left{
  float: left;
}
.div-right{
  float: right;
  text-align: left;
  width: 50%;
}
.font-left{
  width:40% !important;
  float:left;
  line-height: 4rem; 
  text-align: right;
}
.font-input-right{
  width:60% !important;
  float:right
}
.wrapper_dialog_admin{
  background: #2d3d61;
  padding-bottom: 3rem;
}
.wrapper_dialog_admin .container .top{
  font-size: 2rem;
  padding: 1.6rem;
}
.msg {
  font-size: 1rem;
  color: red;
}
.desc_admin{
  margin-top: 0.5rem;
  /* font-size: 1.4rem; */
  font-weight: 500;
}
.desc_admin span{
  margin: 1.5rem;
}
.input_style_admin{
  width: 100%;
  display: block;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  border: 0.1rem solid #4e4d56;
  border-radius: 0.6rem;
  margin-top: 1rem;
  /* color: #E7F3FC; */
  font-family: BebasNeue-Regular;
}
.wrapper_dialog .container .wallet_items button{
  background: url(../../../public/images/box3.png) no-repeat;
  background-size: 100% 100%;
}
.wallet_items a,.wallet_items button{
  font-size: 1.2rem!important;
  border-radius: 0.6rem;
  justify-content: center!important;
}
.w100{
  width: 100%;
  background: url(../../../public/images/box5.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<script>


export default {
  name: "AdminPanel",
  props: ["id"],
  data() {
    return {


    };
  },
  created: function () {
    

  }
};
</script>
<style scoped></style>
